import React from "react"
import styles from "./Expectations.module.scss"
import { Row, Column } from "components/grid/Grid"
import { animate } from "utilities/animate"

const Expectations = props => {
  return (
    <div {...animate()}>
      <Row>
        <Column sm="12" md="6" lg="5">
          <p className={styles.paragraph}>
            Imagine building a startup with guaranteed seed funding. Constant
            up-and-downs combined with almost all phases on the road to the
            product-market-fit. That is how it feels to be at&nbsp;M&amp;M.
          </p>
          <p className={styles.paragraph}>
            We are building the workplace of the future, and that especially
            means giving trust, responsibility, and flexibility to the people.
            Instead of being big in hierarchies, we offer a positive and
            collaborative atmosphere and straight talk. You’ll have mentors and
            collaborators, not commanders. To put it this way – you’ll never get
            bossed around. You’ll receive trust and take your
            own&nbsp;initiative.
          </p>
        </Column>
        <Column sm="12" md="6" lg="5">
          <p className={styles.paragraph}>
            We don’t like strict structures and processes. Instead, we iterate
            our core approach and adapt it to the objectives of the project. In
            addition to that, we embrace distributed working culture; it is not
            about where you work; it’s about the value you are giving to
            the&nbsp;team.
          </p>
          <p className={styles.paragraph}>
            Last but not least, we like to have fun and learn new stuff. Cool
            team events and investing in the personal development of each team
            member is fundamental for&nbsp;us.
          </p>
        </Column>
      </Row>
    </div>
  )
}

export default Expectations
