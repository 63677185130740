import React from "react"
import PropTypes from "prop-types"
import { Section, SectionTitle } from "../../../section"
import styles from "./WhyMartian.module.scss"

const WhyMartian = () => {
  return (
    <Section
      order="01"
      title="Why M&amp;M"
      noBottomSpacing
      background={"transparent"}
      className={styles.wrapper}
    >
      <SectionTitle noBottomSpacing>
        We want to trade your talent for the opportunity to create a significant
        change and receive a network that can support you throughout your
        whole&nbsp;career.
      </SectionTitle>
    </Section>
  )
}

WhyMartian.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

WhyMartian.defaultProps = {
  className: "",
  children: undefined,
}

export default WhyMartian
