import React from "react"
import styles from "./Values.module.scss"
import { animate } from "utilities/animate"
import imgDo from "./assets/do.svg"
import imgChanges from "./assets/changes.svg"
import imgData from "./assets/data.svg"
import imgPositivity from "./assets/positivity.svg"
import imgSoft from "./assets/soft.svg"
import imgResponsibility from "./assets/responsibility.svg"
import cn from "classnames"

const Values = () => {
  return (
    <div className={styles.values}>
      <div className={styles.value} {...animate({ delay: 100 })}>
        <div className={styles.valueIcon}>
          <img src={imgDo} alt="Do" />
        </div>
        <h4 className={cn(styles.valueTitle, "serif")}>Do.</h4>
        <p className={styles.valueText}>Don’t overthink, do!</p>
      </div>

      <div className={styles.value} {...animate({ delay: 100 })}>
        <div className={styles.valueIcon}>
          <img src={imgChanges} alt="Changes" />
        </div>
        <h4 className={cn(styles.valueTitle, "serif")}>Embrace change.</h4>
        <p className={styles.valueText}>Things can and will change.</p>
      </div>

      <div className={styles.value} {...animate({ delay: 200 })}>
        <div className={styles.valueIcon}>
          <img src={imgData} alt="Data" />
        </div>
        <h4 className={cn(styles.valueTitle, "serif")}>
          First data, then perfection.
        </h4>
        <p className={styles.valueText}>Trust data and start small.</p>
      </div>

      <div className={styles.value} {...animate({ delay: 200 })}>
        <div className={styles.valueIcon}>
          <img src={imgPositivity} alt="Positivity" />
        </div>
        <h4 className={cn(styles.valueTitle, "serif")}>Positivity.</h4>
        <p className={styles.valueText}>Be nice and look on the bright side.</p>
      </div>

      <div className={styles.value} {...animate({ delay: 300 })}>
        <div className={styles.valueIcon}>
          <img src={imgSoft} alt="Soft" />
        </div>
        <h4 className={cn(styles.valueTitle, "serif")}>
          Soft, but no bullshit.
        </h4>
        <p className={styles.valueText}>
          Communicate directly and respectfully.
        </p>
      </div>
      <div className={styles.value} {...animate({ delay: 300 })}>
        <div className={styles.valueIcon}>
          <img src={imgResponsibility} alt="Responsibility" />
        </div>
        <h4 className={cn(styles.valueTitle, "serif")}>
          Flexibility = responsibility.
        </h4>
        <p className={styles.valueText}>Own it and take responsibility.</p>
      </div>
    </div>
  )
}

export default Values
