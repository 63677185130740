import React, { Component } from "react"
import { navigate } from "gatsby"
import animateScrollTo from "animated-scroll-to"
import { withGlobalContext } from "components/context/GlobalContext"
import { InView } from "react-intersection-observer"
import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { Hero } from "components/hero"
import { Section, SectionText, SectionTitle } from "components/section/Section"
import Expectations from "components/sections/careers/expectations/Expectations"
import Values from "components/sections/careers/values/Values"
import WhyMartian from "../components/sections/careers/why-martian/WhyMartian"
import { ButtonLink } from "../components/button"
import Positions from "../components/sections/careers/positions/Positions"

class Careers extends Component {
  constructor(props) {
    super(props)

    this.openingsSectionRef = React.createRef()
  }

  scrollToOpenings = () => {
    this.props.context.disableHeaderScrollEvents()
    animateScrollTo(this.openingsSectionRef.current, {
      maxDuration: 1000,
      minDuration: 500,
      cancelOnUserAction: false,
    }).then(() => {
      this.props.context.enableHeaderScrollEvents()
    })
  }

  handleScrollToOpenings = (event) => {
    event.preventDefault()
    this.scrollToOpenings()
  }

  sectionOnChange = (slug, inView) => {
    if (inView && slug === "openings" && window.location.hash !== "#openings") {
      navigate("/careers/#openings", { replace: true })
    }
  }

  componentDidMount = () => {
    if (this.props.location.hash === "#openings") this.scrollToOpenings()
  }

  render() {
    const { location, pageContext } = this.props
    const jobs = pageContext.jobs || []

    const heroProps = {
      image: "careers",
      subtitle: (
        <>
          If you are looking for a challenge, want to make an impact with your
          work, and believe in doing and practicality, then we are a perfect
          match.
        </>
      ),
      title: (
        <>
          <u>Empowering</u> collective talent
        </>
      ),
      button: (
        <ButtonLink
          variant="primary"
          href="#openings"
          onClick={(event) => this.handleScrollToOpenings(event)}
        >
          See our current openings
        </ButtonLink>
      ),
    }

    return (
      <Layout location={location}>
        <Meta
          title="Careers"
          description={
            "Join a team of technology experts that develop software products and turn them into successful ventures. Check out Martian & Machine careers."
          }
        />
        <Hero {...heroProps} />
        <WhyMartian />
        <Section
          order="02"
          title="What can you expect"
          padding={"bigBottomSmall"}
        >
          <Expectations />
        </Section>
        <Section order="03" title="Values" background="dark">
          <SectionTitle className={"serif"}>
            Values that define us.
          </SectionTitle>
          <SectionText animated={true}>
            <p>
              Throughout our evolution as a company, we have continuously
              reflected upon decisions that shaped us in who we become.
              Therefore, we have converged those decisions into simple values
              that provide us a framework for our daily decisions and help us
              set goals and expectations transparently.
            </p>
            <p>
              The following <strong>6 core values</strong> define what's
              important to us&nbsp;at&nbsp;M&amp;M:
            </p>
          </SectionText>
          <Values />
        </Section>
        <InView
          threshold={0.25}
          onChange={(inView) => this.sectionOnChange("openings", inView)}
        >
          <Section
            order="04"
            title="Open positions"
            innerRef={this.openingsSectionRef}
            padding={"bigTopSmall"}
            id="openings"
          >
            <Positions jobs={jobs} />
          </Section>
        </InView>
      </Layout>
    )
  }
}

export default withGlobalContext(Careers)
