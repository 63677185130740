import React from "react"
import PropTypes from "prop-types"
import { Careers } from "../../../../constants/Careers"
import { Position } from "./Position"

const Positions = (props) => {
  const { jobs } = props

  const openJobApplication = jobs.find(
    (job) => job.id === Careers.OpenJobApplicationId
  )

  return (
    <div>
      {jobs
        .filter((job) => job.id !== Careers.OpenJobApplicationId)
        .map((job) => (
          <Position key={job.id} job={job} />
        ))}

      {openJobApplication && <Position job={openJobApplication} />}
    </div>
  )
}

Positions.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      department: PropTypes.oneOf([
        "Management",
        "Venture Operations",
        "Design",
        "Product",
        "Marketing",
        "Engineering",
        "People & Culture",
      ]),
      employmentType: PropTypes.string,
      id: PropTypes.string,
      jobDescriptions: PropTypes.object,
      name: PropTypes.string,
      occupation: PropTypes.string,
      occupationCategory: PropTypes.string,
      office: PropTypes.string,
      recruitingCategory: PropTypes.string,
      schedule: PropTypes.string,
      seniority: PropTypes.string,
      yearsOfExperience: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
}

export default Positions
