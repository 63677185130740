import styles from "./Positions.module.scss"
import { animate } from "../../../../utilities/animate"
import iconCode from "./assets/code.svg"
import iconDesign from "./assets/design.svg"
import iconManagement from "./assets/management.svg"
import iconOperations from "./assets/operations.svg"
import iconPerson from "./assets/person.svg"
import iconIPhone from "./assets/iphone.svg"
import iconMarketing from "./assets/marketing.svg"
import iconCoverLetter from "./assets/cover-letter.svg"

import { Link } from "../../../link"
import React from "react"
import PropTypes from "prop-types"

const getDerivedEmploymentType = (employmentType) => {
  if (employmentType === "permanent") {
    return "full-time"
  }
  return employmentType
}

const DepartmentIcon = ({ department }) => {
  if (department === "Management") {
    return (
      <img
        className={styles.jobIconImage}
        src={iconManagement}
        alt="Management"
      />
    )
  }

  if (department === "Venture Operations") {
    return (
      <img
        className={styles.jobIconImage}
        src={iconOperations}
        alt="Venture Operations"
      />
    )
  }

  if (department === "Design") {
    return <img className={styles.jobIconImage} src={iconDesign} alt="Design" />
  }

  if (department === "Product") {
    return (
      <img className={styles.jobIconImage} src={iconIPhone} alt="Product" />
    )
  }

  if (department === "Marketing") {
    return (
      <img
        className={styles.jobIconImage}
        src={iconMarketing}
        alt="Marketing"
      />
    )
  }

  if (department === "Engineering") {
    return (
      <img className={styles.jobIconImage} src={iconCode} alt="Engineering" />
    )
  }

  if (department === "People & Culture") {
    return <img className={styles.jobIconImage} src={iconPerson} alt="Person" />
  }

  return (
    <img
      className={styles.jobIconImage}
      src={iconCoverLetter}
      alt="Cover letter"
    />
  )
}

export function Position(props) {
  return (
    <div className={styles.job} {...animate()}>
      <div className={styles.jobIcon}>
        <DepartmentIcon department={props.job.department} />
      </div>
      <Link
        to={`/careers/${props.job.slug}/`}
        className={styles.jobTitleWrapper}
      >
        <h2 className={styles.jobTitle}>{props.job.name}</h2>
      </Link>
      <ul className={styles.jobTags}>
        {props.job.office && (
          <li className={styles.jobTag}>{props.job.office}</li>
        )}
        {props.job.employmentType && (
          <li className={styles.jobTag}>
            {getDerivedEmploymentType(props.job.employmentType)}
          </li>
        )}
      </ul>
    </div>
  )
}

Position.propTypes = { job: PropTypes.any }
